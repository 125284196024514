@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Avenir', Arial, Helvetica, sans-serif, 'Open Sans', Baskerville,
    'Times New Roman', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.brand-dark-blue {
  background-color: #152340;
}

.brand-light-blue {
  background-color: #51def5;
}

.brand-orange {
  background-color: #da8f67;
}

.ql-editor {
  min-height: 100px;
}

@media only screen and (max-width: 500px) {
  .startChallenge {
    flex-direction: column-reverse;
  }
}

.setup-buttons {
  width: 60%;
}

.onboarding-glass-bg {
  height: 100vh;
  width: 40%;
  background: #faf9f6;
  box-shadow: 0 2px 5px 0 rgba(136, 136, 137, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.width-80 {
  width: 80%;
  margin: 0 aut0;
}

/* @media only screen and (max-width: 768px) {
  .setUp-glassBg {
    display: none;
  }

  .setup-buttons {
    width: 100%;
  }
} */

@media only screen and (max-width: 500px) {
  .width-80 {
    width: 90%;
  }
  .setup-buttons {
    width: 60%;
    margin: 70px auto 30px;
  }
}
@media only screen and (max-width: 640px) {
  .setup-buttons {
    width: 100%;
    margin: 70px auto 30px;
  }
}
